import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button"
import ReactPlayer from 'react-player'
import { TagContext } from "../components/TagProvider";
import { AddContext } from "../components/AddProvider";
import {LanguageContext} from "../components/LanguageProvider";

import app from "../base";
import TelegramIcon from '@material-ui/icons/Telegram';

import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  sidebarAboutBox: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    marginTop: 5
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
  text:{
    color:theme.palette.common.white
  },
  disButton:{
    margin: 5,
    width: "90%",
    alignContent:"center",
    justifyContent:"center",
    alignSelf:"center"
  },
  disButtonContainer: {
    display:"flex"
  },
  disTitle:{
    color: "#fff",
    fontWeight:"bold",
    fontSize:12
  }
}));
const db = app.firestore()

export default function Sidebar(props) {
  const history = useHistory();
  const classes = useStyles();
  const [loadingVideo, setLoadingVideo] = React.useState(true);
  const [videoUrl, setVideoUrl] = React.useState(true);
  const {tags, loading} = React.useContext(TagContext);
  const {adds} = React.useContext(AddContext)
  const [autoPlayOn, setAutoPlayOn] = React.useState(false);
  const {isKiril} = React.useContext(LanguageContext)

  React.useEffect(() => {
    const fetchData = async => {
      setLoadingVideo(true)
      db.collection("side")
      .doc("video")
      .get()
      .then((doc) => { 
        setVideoUrl(doc.data().url)
        setAutoPlayOn(doc.data().auto_play)
        setLoadingVideo(false)
      }) 
    }
    fetchData()
  },[])

  return (
    <Grid item xs={12} md={4}>
      <Grid style={{ marginTop:5}}>
        {
          !loadingVideo
          ?
          <ReactPlayer playing={autoPlayOn} height={250} width="100%" url={videoUrl}/>
          :
          null
        }
      </Grid>
      <Grid item xs={12}>
        {
          adds.side_one
          ?
          <img onClick={()=> window.open(adds.side_one, "_blank")} style={{width: "100%", marginTop:5}} src={adds.side_one} alt="reklama"/>
          :
          null
        }
      </Grid>
      <Paper elevation={0} className={classes.sidebarAboutBox}>
        <Grid style={{padding:0}} onClick={()=> window.open("https://t.me/shifoinfo_official", "_blank")} container>
          <Grid item xs={1}>
            <TelegramIcon style={{color:"#1B8EC9", fontSize: 35, textAlign:"center"}}/>
          </Grid>
          <Grid item xs={11}>
            <Typography className={classes.text} variant="h6" gutterBottom>
              {isKiril ? "Телеграм каналимизга аъзо бўлинг" : "Telegram kanalimizga a'zo bo‘ling"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      {/* The diseases are described below: */}
      <Grid container style={{backgroundColor:"#DBF3CD", borderRadius:10, marginTop: 5}}>
        <Grid item xs={12}>
          <Typography style={{marginTop:20, textAlign:"center", fontWeight:"bold"}} variant="h6" gutterBottom className={classes.sidebarSection}>
            {isKiril ? "КАСАЛЛИКЛАР РЎЙХАТИ" : "KASALLIKLAR RO‘YXATI"}
          </Typography>
        </Grid>
        <Grid justify="center" alignItems="center" container item xs={6} className={classes.disButtonContainer}>
          {
            !loading
            ?
            tags.dis_new.sort((a, b)=>{
              if(a.slug[0] < b.slug[0]){
                return -1
              }
              if(a.slug[0] > b.slug[0]) {
                return 1
              }
              return 0
            }).slice(0,26).map((dis, index) => (
              <Grid key={dis.slug} item xs={12}>
                <Button className={classes.disButton} variant="contained" color="primary" onClick={
                  () => {history.push({
                    pathname: `/${dis.slug}`,
                    state:{
                      is_dis:true
                    }
                  })}
                }>
                  <Typography className={classes.disTitle}>
                    {isKiril ? dis.kiril : dis.latin}
                  </Typography>
                </Button>
              </Grid>
            ))
            :
            null
          }
        </Grid>
        <Grid container item xs={6} className={classes.disButtonContainer}>
          {
            !loading
            ?
            tags.dis_new.sort((a, b)=>{
              if(a.slug[0] < b.slug[0]){
                return -1
              }
              if(a.slug[0] > b.slug[0]) {
                return 1
              }
              return 0
            }).slice(26,).map((dis, index) => (
              <Grid key={dis.slug} item xs={12}>
                <Button className={classes.disButton} variant="contained" color="primary" onClick={
                  () => {history.push({
                    pathname: `/${dis.slug}`,
                    state:{
                      is_dis:true
                    }
                  })}
                }>
                  <Typography className={classes.disTitle}>
                    {isKiril ? dis.kiril : dis.latin}
                  </Typography>
                </Button>
              </Grid>
            ))
            :
            null
          }
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {
          adds.side_two
          ?
          <img onClick={()=> window.open(adds.side_two, "_blank")} style={{width: "100%", marginTop:5}} src={adds.side_two} alt="reklama"/>
          :
          null
        }
      </Grid>
      <Grid item xs={12}>
        {
          adds.side_three
          ?
          <img onClick={()=> window.open(adds.side_three, "_blank")} style={{width: "100%", marginTop:5}} src={adds.side_three} alt="reklama"/>
          :
          null
        }
      </Grid>
    </Grid>
  );
}

Sidebar.propTypes = {
  archives: PropTypes.array,
  description: PropTypes.string,
  social: PropTypes.array,
  title: PropTypes.string,
};
