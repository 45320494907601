import React, {useState, useEffect, useContext} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useHistory, useRouteMatch, useLocation} from "react-router-dom";
import Button from "@material-ui/core/Button";
import ListItemPost from "./ListItemPost";
import LinearProgress from "@material-ui/core/LinearProgress";
import {LanguageContext} from "../components/LanguageProvider"

import app from "../base";

import { TagContext } from "../components/TagProvider";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  logo:{
    height:50,
    width:250,
    alignSelf:"center"
  },
  logoContainer:{
    flex:1,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  drawer:{
    width: 250
  },
  subTagFilterText:{
    color:theme.palette.common.white
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  }, 
  buttonText:{
    color: theme.palette.common.white
  }
}));
const db = app.firestore();

export default function FilteredPosts(props){
  const { url, path } = useRouteMatch()
  const history = useHistory()
  const classes = useStyles()
  const location = useLocation()
  const {is_dis} = location.state || {is_dis:false}
  const { filterTag } = useParams()
  const { tags, loading } = useContext(TagContext)
  const [selectedSubTag, setSelectedSubTag] = useState(null)
  const [fetchLoading, setFetchLoading] = useState(true)
  const [articles, setArticles] = useState([])
  
  const [lastItem, setLastItem] = useState(null);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [limitNumber, setLimitNumber] = useState(12)
  const {isKiril} = React.useContext(LanguageContext)

  const handleMore = () => {
    if(!lastItem){
      return null
    }
    setFetchingMore(true)
    if(is_dis){
      db.collection("articles").where("dis", "array-contains", filterTag)
      .where("published", "==", true)
      .orderBy("date_created", "desc").startAfter(lastItem)
      .limit(limitNumber).get()
      .then(snapshot => {
        if(snapshot.docs.length < limitNumber){
          setLastItem(false)
        } else {
          setLastItem(snapshot.docs[snapshot.docs.length-1])
        }
        let articles = []
        snapshot.forEach(doc=>{
          articles.push({...doc.data(), id:doc.id})
        })
        setArticles(prevState =>([...prevState, ...articles ]))
        setFetchingMore(false)
      })
    } else {
      db.collection("articles").where("tags", "array-contains", filterTag)
      .where("published", "==", true)
      .orderBy("date_created", "desc").startAfter(lastItem)
      .limit(limitNumber).get()
      .then(snapshot => {
        if(snapshot.docs.length < limitNumber){
          setLastItem(false)
        } else {
          setLastItem(snapshot.docs[snapshot.docs.length-1])
        }
        let articles = []
        snapshot.forEach(doc=>{
          articles.push({...doc.data(), id:doc.id})
        })
        setArticles(prevState =>([...prevState, ...articles ]))
        setFetchLoading(false)
      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setFetchLoading(true)
      if(is_dis){
        db.collection("articles").where("dis", "array-contains", filterTag)
        .where("published", "==", true)
        .orderBy("date_created", "desc")
        .limit(limitNumber).get()
        .then(snapshot => {
          if(snapshot.docs.length < limitNumber){
            setLastItem(false)
          } else {
            setLastItem(snapshot.docs[snapshot.docs.length-1])
          }
          let articles = []
          snapshot.forEach(doc=>{
            articles.push({...doc.data(), id:doc.id})
          })
          setArticles(articles)
          setFetchLoading(false)
        })
      } else {
        db.collection("articles").where("tags", "array-contains", filterTag)
        .where("published", "==", true)
        .orderBy("date_created", "desc")
        .limit(limitNumber).get()
        .then(snapshot => {
          if(snapshot.docs.length < limitNumber){
            setLastItem(false)
          } else {
            setLastItem(snapshot.docs[snapshot.docs.length-1])
          }
          let articles = []
          snapshot.forEach(doc=>{
            articles.push({...doc.data(), id:doc.id})
          })
          setArticles(articles)
          setFetchLoading(false)
        })
      }
    }
    fetchData()
    return function cleanup(){
      setSelectedSubTag(null)
      window.scrollTo(0, 0)  
    }
  },[filterTag])
  
  return (
    <Grid item xs={12} md={8}>
      {
        is_dis
        ?
        <Typography variant="h5" gutterBottom>
          {
            !loading 
            ?
            `${isKiril ? tags.dict_dis[filterTag].kiril : tags.dict_dis[filterTag].latin}`
            : 
            ""
          }
        </Typography>
         :
        <Typography variant="h5" gutterBottom>
          {
            !loading 
            ?
            `${ isKiril ? tags.dict_tags[filterTag].kiril : tags.dict_tags[filterTag].latin}`
            : 
            ""
          }
        </Typography>
      }
      <Divider />
        {
          !fetchLoading 
          ?
          <Grid xs={12} style={{marginTop:2}} item container spacing={2}>
            {
              articles.map(article => {
                return(
                  <Grid key = {article.id} xs={6} md={4} item>
                    <ListItemPost post={article}/>
                  </Grid>
                )
              })
            }
            <Grid xs={12} item container>
              <Button className = {classes.buttonText} fullWidth color={lastItem ? "primary" : "default"} variant="contained" onClick={handleMore}>
                {lastItem ? isKiril ? "Кўпроқ мақолалар юклаш":"Ko‘proq maqolalar yuklash" : "..." }
              </Button>
            </Grid>
          </Grid>
          :
          <Grid item xs={12}>
            <div className={classes.progress}>
              <LinearProgress />
            </div>
          </Grid>
        }
    </Grid>
  );
}
