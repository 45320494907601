import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Paper from "@material-ui/core/Paper";
import {LanguageContext} from "../components/LanguageProvider";
import {dateConverter} from '../components/dateConverter';
import {dateConverterLatin} from "../components/dateConverterLatin";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 120,
        borderWidth:2,
        borderColor:"black",
    },
    details: {
        display: 'flex',
        flex:1,
        flexDirection: 'column',
    },
    content: {
        flex: 2,
        paddingBottom: 5,
        paddingLeft:8,
        paddingRight: 5
    },
    cover: {
        flex:1
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
    },
    image: {
        objectFit:"cover",
        width: "100%",
        height: 120
    },
    mainFeaturedPostContent: {
        position: 'relative',
    },
}));

export default function WideItem(props) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory()
    const { post } = props;
    const mainTextColor = "#000"
    const [isHovering, setIsHovering] = React.useState(false)
    const {isKiril} = React.useContext(LanguageContext)

    return (
        <Paper elevation={isHovering ? 5 : 0}>
            <Grid onClick={() => { history.push({ pathname : `/article/${post.id}`, state : { articleData : post}})}} onMouseEnter = {() => { setIsHovering(true) }} onMouseLeave={() => {setIsHovering(false)}} container className={classes.root}>
                <Grid item className={classes.content}>
                    <div className={classes.mainFeaturedPostContent}>
                        <Typography style={{display: 'inline-block', fontSize:12, fontWeight:"bold"}} variant="subtitle1" color="primary">
                            {isKiril ? dateConverter(post.date_created.toDate()) : dateConverterLatin(post.date_created.toDate())}
                        </Typography>
                        <Typography color="primary" style={{display: 'inline-block', marginLeft:20, fontSize:12, fontWeight:"bold"}}>
                            <VisibilityIcon style={{ display:"inline-block", marginRight:8, fontWeight:"bold", paddingTop:11}} className={classes.wrapIcon} color="primary"/>
                            {typeof(post.views)=="number" ? post.views : 1 }
                        </Typography>
                        <Box style={{display:"inline-block", height:120}}>
                            <Typography component="h3" variant="h6" color="primary" style={{fontSize:12, color:"#000", fontWeight:"bold"}}>
                                {
                                    post.title.length > 100
                                    ? 
                                    `${isKiril ? post.title.slice(0,100) : post.isLatinAvailable ? post.title_latin.slice(0,100) : post.title.slice(0,100)}...`
                                    :
                                    `${isKiril ? `${post.title}`.slice(0,55) : post.isLatinAvailable ? `${post.title_latin}`.slice(0,55) : `${post.title}`.slice(0,55)}`
                                }
                            </Typography>
                            <Typography>
                                {
                                    post.title.length < 35
                                    ?
                                    post.promotional_text.length > 35
                                    ?
                                    `${isKiril ? post.promotional_text.slice(0,35): post.isLatinAvailable ? post.promotional_text_latin.slice(0,35) : post.promotional_text.slice(0,35)}...`
                                    :
                                    `${isKiril ? post.promotional_text: post.isLatinAvailable ? post.promotional_text_latin : post.promotional_text}`
                                    :
                                    null

                                }
                            </Typography>
                        </Box>
                    </div>
                </Grid>
                <Grid item className={classes.cover}>
                    <img className={classes.image} src={post.image_as_cover} />
                </Grid>

            </Grid>
        </Paper>
    );
}