import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";
import image from "../assets/blur2.png"
import VisibilityIcon from '@material-ui/icons/Visibility';
import {dateConverter} from "../components/dateConverter";
import {dateConverterLatin} from "../components/dateConverterLatin";
import { LanguageContext } from "../components/LanguageProvider"

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    width:"100%", 
    height:"100%",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(255,250,250,.7)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      paddingRight: 0,
    },
  },
  buttonText:{
    color: theme.palette.common.white
  }
}));

export default function MainFeaturedPost(props) {
  const history = useHistory()
  const classes = useStyles();
  const { post } = props;
  const { isLatinAvailable } = post || { isLatinAvailable:false }
  const { isKiril } = React.useContext(LanguageContext)
  const [ isHovering, setIsHovering ] = React.useState(false)
  const mainTextColor = "#000"
  return (
    <Grid container item xs={12} md={4} onMouseEnter = {() => { setIsHovering(true) }} onMouseLeave={() => {setIsHovering(false)}}>
      <Paper onClick={()=>{history.push({
        pathname:`/article/${post.id}`,
        state:{articleData: post}
      })}} className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${post.image_as_cover})`, height: 260, width:"100%"}}>
        {/* Increase the priority of the hero background image */}
        {/* {<img style={{ display: 'none' }} src={post.image_as_cover} alt={post.title} />} */}
        {
          isHovering
          ?
          <div className={classes.overlay} />
          :
          <img src={image} alt={post.title} style={{width:"100%", height:"100%", position:"absolute",}} />    
        }
        <Grid container>
          <div className={classes.mainFeaturedPostContent}>
            
            {
              isHovering
              ?
              <Grid container item xs={12} md={12} style={{margin:5, height:"100%"}}>
                <Typography style={{color:mainTextColor, fontWeight:"bold"}} variant="h5">
                  {
                    post.promotional_text.length > 130 ?
                    `${isKiril ? post.promotional_text.slice(0, 130) : isLatinAvailable ? post.promotional_text_latin.slice(0, 130) : post.promotional_text.slice(0, 130) }...`:
                    `${isKiril ? post.promotional_text : isLatinAvailable ? post.promotional_text_latin : post.promotional_text }...`
                  }
                </Typography>
                <Typography style={{color:"#13681B", fontWeight:"bold"}} variant="subtitle1" color="primary">
                  {isKiril ? dateConverter(post.date_created.toDate()): dateConverterLatin(post.date_created.toDate())}
                </Typography>
                <Typography style={{color:"#13681B", marginLeft:20, fontWeight:"bold"}}>
                  <VisibilityIcon style={{marginRight:8, color:"#13681B"}} className={classes.wrapIcon}/>
                </Typography>
                <Typography style={{color:"#13681B", fontWeight:"bold"}}>
                  {typeof(post.views)=="number" ? post.views : 1 }
                </Typography>
              </Grid>
              :
              <React.Fragment>
              <Grid container item>
                <Grid item xs={8} md={8} style={{margin:2, fontWeight:"bold"}}>
                  <Typography style={{color:mainTextColor}} component="h1" variant="h4" gutterBottom>
                    {isKiril ? post.title : post.title_latin ? post.title_latin : post.title}
                  </Typography>
                </Grid>
              </Grid>
              </React.Fragment>
            }
          </div>
        </Grid>
      </Paper>
    </Grid>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.object,
}
