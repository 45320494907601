import React, { useState} from "react";

export const LanguageContext = React.createContext()

export const LanguageProvider = ({children}) => {
    const [isKiril, setIsKiril] = useState(true);
    return(
        <LanguageContext.Provider value={{ isKiril, setIsKiril }}>
            {children}
        </LanguageContext.Provider>
    )
}
