import React from 'react';
import {ThemeProvider} from "@material-ui/core/styles"
import {theme} from "./theme"
import {BrowserRouter as Router} from "react-router-dom"
//Page imports
import Blog from "./blog/Blog";
import { TagProvider } from "./components/TagProvider"
import { AddProvider } from "./components/AddProvider"
import { LanguageProvider } from "./components/LanguageProvider"

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <TagProvider>
          <AddProvider>
            <Router>
              <Blog/>
            </Router>
          </AddProvider>
        </TagProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
