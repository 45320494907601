import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { LanguageContext } from "../components/LanguageProvider";

export default function Aloqa(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const {isKiril} = React.useContext(LanguageContext)
  return (
    <Grid item xs={12} md={8}>
      <Typography variant="h5" gutterBottom>
        {
          isKiril
          ?
          "Биз билан алоқага чиқинг"
          :
          "Biz bilan aloqaga chiqing"
        } 
      </Typography>
      <Divider />
      <Grid container item md={8} xs={12}>
        <Grid xs={12} item>
          <Typography variant="h6">
            {
              isKiril
              ?
              "Бошқа нашрлар билан ҳамкорликда ишлашдан доим мамнун бўламиз."
              :
              "Boshqa nashrlar bilan hamkorlikda ishlashdan doim mamnun bo‘lamiz."
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            {
              isKiril
              ?
              "Мурожат учун:"
              :
              "Murojat uchun:"
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {
              isKiril
              ?
              "Почта: shifo.info@bk.ru"
              :
              "Pochta: shifo.info@bk.ru"
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            {
              isKiril
              ?
              "Реклама бўлими:"
              :
              "Reklama bo‘limi:"
            }
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="subtitle1">
            {
              isKiril
              ?
              "Тел: (71) 209-71-12; (71) 209-49-15 (Қўнғироқлар 09:00 дан 18:00 гача қабул қилинади)"
              :
              "Tel: (71) 209-71-12; (71) 209-49-15 (Qo‘ng‘iroqlar 09:00 dan 18:00 gacha qabul qilinadi)"
            }
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
