import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from "@material-ui/core/Link"
import app from "../base";
import {dateConverter} from "../components/dateConverter"
import {dateConverterLatin} from "../components/dateConverterLatin"
import {LanguageContext} from "../components/LanguageProvider"
import LinearProgress from "@material-ui/core/LinearProgress";

const db = app.firestore();

export default function Arxiv(props) {
  const [archives, setArchives] = React.useState([])
  const {isKiril} = React.useContext(LanguageContext)
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(()=>{
    setLoading(true)
    db.collection("archive").get()
    .then((snapshot) => {
      let archives = []
      snapshot.forEach(doc => {
        archives.push({...doc.data(), id: doc.id})
      })
      setArchives(archives)
      setLoading(false)
    })    
  },[])

  return (
    <Grid item xs={12} md={8}>
      <Typography variant="h5" gutterBottom>
        {
          isKiril
          ?
          "ШИФО-ИНФО газетаси архиви"
          :
          "ShIFO-INFO gazetasi arxivi"
        }
      </Typography>
      <Divider />
      {
        !loading
        ?
        <Grid item xs={12}>
          {
            archives.map(archive => {
              return (
                <React.Fragment>
                  <Typography style={{fontWeight:"bold", color: "#13681B"}}>
                    {isKiril ? dateConverter(archive.date.toDate()) : dateConverterLatin(archive.date.toDate())}
                  </Typography>
                  <Typography style={{fontWeight:"bold"}}>
                    {isKiril ? archive.kiril : archive.latin}
                  </Typography>
                  <Link color="primary" component="button" variant="body2" onClick={()=> window.open(archive.link, "_blank")}>
                    {isKiril ? "Кўчириш" : "Ko'chirish"}
                  </Link>
                  <Divider/>
                </React.Fragment>
              )
            })
          }
        </Grid>
        :
        <Grid item xs={12}>
          <div style={{width: "100%"}}>
            <LinearProgress />
          </div>
        </Grid>
      }
    </Grid>
  );
}
