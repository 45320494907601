import React, { useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import image from "../assets/SHIFOINFO.UZ.png"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import ForumIcon from '@material-ui/icons/Forum';
import InfoIcon from '@material-ui/icons/Info';
import LabelIcon from '@material-ui/icons/Label';
import LinearProgress from '@material-ui/core/LinearProgress';
import ButtonGroup from "@material-ui/core/ButtonGroup";
// import {tags} from "../tags";
import {withRouter, useHistory} from "react-router-dom";
import { TagContext } from "../components/TagProvider";
import { AddContext } from "../components/AddProvider";
import { LanguageContext } from "../components/LanguageProvider";

const useStyles = makeStyles((theme) => ({
  menuButton:{
    color: "#FFF"
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor:"#00A654",
    marginBottom:3
  },
  logo:{
    height:50,
    width:250,
    alignSelf:"center"
  },
  logoContainer:{
    flex:1,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    backgroundColor:"#0ff"
  },
  toolbarLast: {
    justifyContent: 'flex-start',
    overflowX: 'auto', 
  },
  toolbarLink: {
    // padding: theme.spacing(1),
    // flexShrink: 1,
  },
  drawer:{
    width: 300,
    backgroundColor: "#DBF3CD"
  },
  buttonText:{
    color: theme.palette.background.paper,
    fontWeight:"bold",
    marginRight: 10
  },
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0, 0.6)',
    width: "30%"
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      paddingRight: 0,
    },
  },
  headerImageText:{
    color:theme.palette.common.white
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  navBarButton : {
    color: theme.palette.mycolor1
  },
  drawerText: {
    color:"#13681B"
  }
}));

console.log(window.innerHeight)

const Header = (props) => {
  const history = useHistory()
  const classes = useStyles();
  const { tags, loading } = React.useContext(TagContext)
  const { adds } = React.useContext(AddContext)
  const { isKiril, setIsKiril } = React.useContext(LanguageContext)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [whetherData, setWhetherData] = React.useState(null)
  const toggleDrawer = (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(!openDrawer)
  }

  const drawerList = () => (
    <div
      className={classes.drawer}
      role="presentation"
      onClick={event=> toggleDrawer(event)}
      onKeyDown={event=> toggleDrawer(event)}
    >
      <List>
        <ListItem button onClick={()=>{history.push('/')}}>
          <ListItemIcon><HomeIcon color="primary"/></ListItemIcon>
          <ListItemText className={classes.drawerText} primary={isKiril?"Асосий саҳифа":"Asosiy sahifa"}/>
        </ListItem>
        <ListItem button onClick={()=>{history.push('/aloqa')}}>
          <ListItemIcon><ForumIcon color="primary"/></ListItemIcon>
          <ListItemText className={classes.drawerText} primary={isKiril?"Алоқа":"Aloqa"}/>
        </ListItem>
        <ListItem button onClick={()=>{history.push('/haqida')}}>
          <ListItemIcon><InfoIcon color="primary"/></ListItemIcon>
          <ListItemText className={classes.drawerText} primary={isKiril?"Газета ҳақида":"Gazeta haqida"}/>
        </ListItem>
        <ListItem button onClick={()=>{history.push('/interviews')}}>
          <ListItemIcon><LabelIcon color="primary"/></ListItemIcon>
          <ListItemText className={classes.drawerText} primary={isKiril?"Видео суҳбатлар":"Video suhbatlar"}/>
        </ListItem>
      </List>
      <Divider />
      {
        loading
        ?
        <Grid item xs={12}>
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        </Grid>
        :
        <List>
          {tags.all_tags_new.map((tag) => (
            <ListItem button key={tag.slug} onClick={()=>{history.push(`/${tag.slug}`)}}>
              <ListItemIcon><LabelIcon color="primary"/></ListItemIcon>
              <ListItemText className={classes.drawerText} primary={isKiril ? tag.kiril : tag.latin} />
            </ListItem>
          ))}
        </List>
      }
    </div>
  );
  
  useEffect(() => {
    const fetchWhether = async () => {
      let response = await fetch("https://us-central1-shifo-info.cloudfunctions.net/weatherData");
      let jsonResponse = await response.json();
      setWhetherData(jsonResponse.main.temp)
    }
    fetchWhether()
  }, [])

  return (
    <React.Fragment>
      <img onClick={()=> {
        if(adds.header){
          return window.open(adds.header_link, "_blank")
        } else {
          return history.push("/")
        }
      }} alt="reklama" style={{width:"100%", height:"auto"}} src={
        adds.header
        ?
        adds.header
        :
        "https://firebasestorage.googleapis.com/v0/b/shifo-info.appspot.com/o/header.jpg?alt=media&token=542bebd3-b0fc-4a58-8f4f-bb818dcd30b6"
      }/>
      <SwipeableDrawer tags={tags} onOpen={()=>{}} anchor="right" open={openDrawer} onClose={toggleDrawer}>
        {drawerList()}
      </SwipeableDrawer>
      <Toolbar variant="dense" className={classes.toolbar}>
        <Grid container justify="center">
          <Grid item xs={12} md={10} container justify="flex-start">
            <Grid item xs={12} md={2}>
              <Box className={classes.logo} onClick={()=>{history.push('/')}}>
                <img alt="shifoinfo.uz" style={{height:50}} src={image}/>
              </Box>
            </Grid>
            <Hidden smDown>
              <Grid style={{paddingLeft:15}} container justify="flex-start" alignContent="flex-end" item xs={12} md={10}>
                <Typography style={{fontSize:25, color:"#fff"}}>
                  <strong>
                    {
                      isKiril
                      ?
                      "ШИФО-ИНФО ГАЗЕТАСИНИНГ РАСМИЙ ВЕБ-САЙТИ"
                      :
                      "ShIFO-INFO GAZETASINING RASMIY VEB-SAYTI"
                    }
                  </strong>
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item xs={12} md={2} container justify="flex-end">
            {
              whetherData
              ?
              <Button style={{alignSelf:"flex-end"}}>
                <Typography style={{fontSize:25, color:"#fff"}}>
                  {`${Math.round(whetherData - 273.15)} °C`}
                </Typography>
              </Button>
              :
              null
            }
            <hr/>
            <Button style={{alignSelf:"flex-end"}} onClick={(()=>{setIsKiril(prevState => !prevState)})}>
              <Typography style={{fontSize:25, color:"#fff"}}>
                {
                  isKiril
                  ?
                  "UZB"
                  :
                  "ЎЗБ"
                }
                
              </Typography>
            </Button>
            <hr/>
            <IconButton
              className={classes.menuButton}
              aria-label="Menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      {
        loading
        ?
        <Grid item xs={12}>
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        </Grid>
        :
        <Hidden smDown>
          <ButtonGroup style={{marginBottom:2}} fullWidth size="small">
            {tags.all_tags_new.slice(0, 9).map((tag) => (
              <Button
                key={tag.slug}
                variant="contained"
                color="secondary"
                onClick = {()=>{history.push(`/${tag.slug}`)}}
                className = {classes.toolbarLink}
                style = {{paddingLeft:0, paddingRight:0, paddingTop:5}}
              >
                <Typography className={classes.buttonText} style={{margin:0, color:"#13681B"}} variant="caption">
                  {isKiril ? tag.kiril : tag.latin}
                </Typography>
              </Button>
            ))}
          </ButtonGroup>
          
          <ButtonGroup color="secondary" style={{ marginBottom:2}} fullWidth size="small">
            {tags.all_tags_new.slice(9, 18).map((tag) => (
              <Button
                key={tag.slug}
                variant="contained"
                color="secondary"
                onClick = {() => {history.push(`/${tag.slug}`)}}
                className = {classes.toolbarLink}
                style = {{paddingLeft:0, paddingRight:0, paddingTop:5,}}
              >
                <Typography variant="caption" className={classes.buttonText} style={{margin:0, color:"#13681B"}}>
                  {isKiril ? tag.kiril : tag.latin} 
                </Typography>
              </Button>
            ))}
            <Button
                key={"interviews"}
                variant="contained"
                color="secondary"
                onClick = {() => {history.push(`/interviews`)}}
                className = {classes.toolbarLink}
                style = {{paddingLeft:0, paddingRight:0, paddingTop:5,}}
              >
                <Typography variant="caption" className={classes.buttonText} style={{margin:0, color:"#13681B"}}>
                  {isKiril ? "Видео суҳбатлар" : "Video suhbatlar"} 
                </Typography>
              </Button>
          </ButtonGroup>
        
          {
            tags.all_tags_new.length >= 18 ?
            <ButtonGroup color="secondary" style={{marginBottom:2}} fullWidth size="small">
              {tags.all_tags_new.slice(18, ).map((tag) => (
                <Button
                  key={tag.slug}
                  variant="contained"
                  color="secondary"
                  onClick={()=>{history.push(`/${tag.slug}`)}}
                  className={classes.toolbarLink}
                  style = {{paddingLeft:0, paddingRight:0, paddingTop:5}}
                >
                  <Typography variant="caption" className={classes.buttonText} style={{margin:0, color:"#13681B"}}>
                    {isKiril ? tag.kiril : tag.latin} 
                  </Typography>
                </Button>
              ))}
            </ButtonGroup>
            :
            null
          }
      </Hidden>
      }
      
    </React.Fragment>
  );
}

export default withRouter(Header)