import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Box from "@material-ui/core/Box";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LanguageContext} from "../components/LanguageProvider";
import {dateConverter} from '../components/dateConverter';
import {dateConverterLatin} from "../components/dateConverterLatin";

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.6)',
    height:"0%"
  },
  mainFeaturedPostContent: {
    alignSelf:"center",
    position: 'relative',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      paddingRight: 0,
    },
  },
  buttonText:{
    color: theme.palette.common.white
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex'
  }
}));

export default function MainFeaturedPost(props) {
  const history = useHistory()
  const classes = useStyles();
  const { post } = props;
  const mainTextColor = "#000"
  const [isHovering, setIsHovering] = React.useState(false)
  const {isKiril} = React.useContext(LanguageContext)
  return (
    <Paper elevation={isHovering ? 5 : 0} className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${post.image_as_cover})` }}>
      <div className={classes.overlay} />
      <Grid onClick={() => { history.push({ pathname : `/article/${post.id}`, state : { articleData : post}})}} onMouseEnter = {() => { setIsHovering(true) }} onMouseLeave={() => {setIsHovering(false)}} container item xs={12} justify="space-evenly" style={{height: 250}}>
        <Grid alignItems="flex-end" container item xs={12}>
          <Grid container item xs={12} style={{backgroundColor:"#f5f5f5"}}>
            <div className={classes.mainFeaturedPostContent}>
              <Typography style={{display: 'inline-block', fontSize:12, fontWeight:"bold"}} variant="subtitle1" color="primary">
                {isKiril ? dateConverter(post.date_created.toDate()): dateConverterLatin(post.date_created.toDate())}
              </Typography>
              <Typography color="primary" style={{display: 'inline-block', marginLeft:20, fontSize:12, fontWeight:"bold"}}>
                <VisibilityIcon style={{marginRight:8, fontWeight:"bold"}} className={classes.wrapIcon} color="primary"/>
                {typeof(post.views)=="number" ? post.views : 1 }
              </Typography>
              <Box>
                <Typography component="h3" variant="h6" color="primary" style={{fontSize:12, color:"#000", fontWeight:"bold"}}>
                  {
                    post.title.length > 45
                    ? 
                    `${ isKiril ? post.title.slice(0,45) : post.isLatinAvailable ? post.title_latin.slice(0,45) : post.title.slice(0,45)}...`
                    :
                    `${isKiril ? `${post.title} \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`.slice(0,55): post.isLatinAvailable ? `${post.title_latin} \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`.slice(0,55) : `${post.title} \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`.slice(0,55)}`
                  }
                </Typography>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.object,
};
