import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, rgbToHex } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import {useHistory} from "react-router-dom";
import {dateConverter} from "../components/dateConverter";
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(theme=>({
  card: {
    display: 'flex',
    paddign:0
  },
  cardDetails: {
    flex: 1,
    height: "100%",
    // height: 230,
    padding: 5,
    backgroundColor: "rgb(110,255,88,0.2)"
  },
  cardMedia: {
    width: 230,
  },
  postContent: {
    position: 'relative',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
      paddingRight: 0,
    },
  },
  buttonText:{
    color: theme.palette.common.white
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex'
  }
}));

export default function FeaturedPost(props) {
  const history = useHistory()
  const classes = useStyles();
  const { post } = props;

  return (
    <Grid item xs={12} md={6} style={{height:"100%"}}>
      <CardActionArea onClick={()=>{history.push(`/article/${post.id}`)}}>
        <Card elevation={0} className={classes.card}>
          <div className={classes.cardDetails}>
            <CardContent className={classes.postContent}>
              <Typography component="h2" variant="h5">
                {post.title}
              </Typography>
              <Typography style={{display: 'inline-block'}} variant="subtitle1" color="primary">
                {dateConverter(post.date_created.toDate())}
              </Typography>
              <Typography color="primary" style={{display: 'inline-block', marginLeft:20}}>
                <VisibilityIcon style={{marginRight:8}} className={classes.wrapIcon} color="primary"/>
                {typeof(post.views)=="number" ? post.views : 1 }
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {
                  post.promotional_text.length > 85 ?
                  post.promotional_text.slice(0,85) + "...":
                  post.promotional_text
                }
              </Typography>
            </CardContent>
          </div>
        </Card>
      </CardActionArea>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
};
