import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from "@material-ui/core/Link";
import {LanguageContext} from "../components/LanguageProvider";

export default function Haqida(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const {isKiril} = React.useContext(LanguageContext)
  return (
    <Grid item xs={12} md={8}>
      <Typography variant="h5" gutterBottom>
        {
          isKiril
          ?
          "ШИФО-ИНФО газетаси ҳақида"
          :
          "ShIFO-INFO gazetasi haqida"
        }
      </Typography>
      <Divider />
      <Grid container item xs={12}>
        <Typography align="justify" variant="subtitle1" gutterBottom>
          {
            isKiril
            ?
            "«SHIFO-INFO» - Ўзбекистондаги энг фаол тиббий нашрлардан бири. Сайт ўз олдига Ўзбекистон ва жаҳондаги энг муҳим касалликлар ва тиббиёт янгиликлари ҳақида тезкор, аниқ ва холис маълумот беришни ҳамда уларга даво усулларини  ишончли тарзда етказишни ўз олдига мақсад қилиб қўйган.Сайтдан фойдаланиш мутлақо бепул бўлиб, фақатгина интернет траффик сарфланади. Сайтнинг енгил ва тез ишлайдиган мобил версияси - "
            :
            "«SHIFO-INFO» - O‘zbekistondagi eng faol tibbiy nashrlardan biri. Sayt o‘z oldiga O‘zbekiston va jahondagi eng muhim kasalliklar va tibbiyot yangiliklari haqida tezkor, aniq va xolis ma'lumot berishni hamda ularga davo usullarini  ishonchli tarzda yetkazishni o‘z oldiga maqsad qilib qo‘ygan.Saytdan foydalanish mutlaqo bepul bo‘lib, faqatgina internet traffik sarflanadi. Saytning yengil va tez ishlaydigan mobil versiyasi -"
          }
          <Link>https://www.shifoinfo.uz/</Link>
        </Typography>
        <Typography align="justify" variant="subtitle1" gutterBottom>
          {
            isKiril
            ?
            "Fakebook, Instagram, Twitter  ижтимоий тармоқларида саҳифалар, Telegram мессенжерида эса "
            :
            "Fakebook, Instagram, Twitter  ijtimoiy tarmoqlarida sahifalar, Telegram messenjerida esa "
          }
          <Link>t.me/shifoinfo_official</Link> {isKiril ? "номли канал фаолият юритади." : "nomli kanal faoliyat yuritadi."}
          {
            isKiril
            ?
            "'Shifo-info' gazetasi 2006 yilning dekabrida faoliyatini boshlagan. OAV sifatida 0069 raqami bilan davlat ro‘yxatidan o‘tgan. Loyiha muassisi «PRAVO-PRESS» MChJ hisoblanadi.Sayt O‘zbekiston Respublikasi hududida faoliyat olib boruvchi professor va fan doktorlari bilan qonunda belgilangan tartibda hamkorlik o‘rnatgan holda ish olib boradi."
            :
            ""
          }
        </Typography>
        <Typography align="justify" variant="subtitle1" gutterBottom>
          {
            isKiril
            ?
            "Сайт материалларидан бошқа сайтлар ёки ОАВ фойдаланиши шартлари:"
            :
            "Sayt materiallaridan boshqa saytlar yoki OAV foydalanishi shartlari:"
          }
          
        </Typography>
        <Typography align="justify" variant="subtitle1" gutterBottom>
          {
            isKiril
            ?
            "Сайт Ўзбекистон Республикаси ҳудудида, минтақа ва чет элларда фаолият юритаётган интернет сайтлари, нашрлар билан ўзаро маълумот, мақолалар алмашишни йўлга қўяди."
            :
            "Sayt O‘zbekiston Respublikasi hududida, mintaqa va chet ellarda faoliyat yuritayotgan internet saytlari, nashrlar bilan o‘zaro ma'lumot, maqolalar almashishni yo‘lga qo‘yadi."
          }
          
        </Typography>
        <Typography align="justify" variant="subtitle1" gutterBottom>
          {
            isKiril
            ?
            "Сайтдаги маълумотлардан фақат таҳририятдан ёзма равишда рухсат олингандагина фойдаланиш талаб этилади."
            :
            "Saytdagi ma'lumotlardan faqat tahririyatdan yozma ravishda ruxsat olingandagina foydalanish talab etiladi."
          }
        </Typography>
        <Typography align="justify" variant="subtitle1" gutterBottom>
          {
            isKiril
            ?
            "Материаллардан фойдаланишда, сайт номини (муаллифини) берилаётган материалнинг биринчи ёки учинчи қаторигача бўлган ҳудудда тақдим этиш сўралади."
            :
            "Materiallardan foydalanishda, sayt nomini (muallifini) berilayotgan materialning birinchi yoki uchinchi qatorigacha bo‘lgan hududda taqdim etish so‘raladi."
          }
        </Typography>
        <Typography align="justify" variant="subtitle1" gutterBottom>
          {
            isKiril
            ?
            "Масалан, «Бу ҳақда SHIFO-INFO  сайти хабар берди»."
            :
            "Masalan, «Bu haqda SHIFO-INFO  sayti xabar berdi»."
          }
        </Typography>
        <Typography align="justify" variant="subtitle1" gutterBottom>
          {
            isKiril
            ?
            "Бу ўринда нашр номи — «SHIFO-INFO» сўзига ҳавола қўйилиб, бу ҳавола "
            :
            "Bu o‘rinda nashr nomi — «SHIFO-INFO» so‘ziga havola qo‘yilib, bu havola "
          }
          <Link>https://www.shifoinfo.uz/</Link>{
            isKiril
            ?
            "саҳифасига йўналтирилган бўлиши, қолаверса, «хабар беради» сўз бирикмасига ҳавола қўйилиб, бу ҳавола «SHIFO-INFO» сайтидан олинган хабарга йўналтирилган бўлиши керак."
            :
            "sahifasiga yo‘naltirilgan bo‘lishi, qolaversa, «xabar beradi» so‘z birikmasiga havola qo‘yilib, bu havola «SHIFO-INFO» saytidan olingan xabarga yo‘naltirilgan bo‘lishi kerak."
          } 
        </Typography>
        <Typography align="justify" variant="subtitle1" gutterBottom>
          {
            isKiril
            ?
            "Бошқа нашрлар билан ҳамкорликда ишлашдан доим мамнун бўламиз."
            :
            "Boshqa nashrlar bilan hamkorlikda ishlashdan doim mamnun bo‘lamiz."
          }
        </Typography>
      </Grid>
      <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            {
              isKiril
              ?
              "Мурожат учун:"
              :
              "Murojat uchun:"
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {
              isKiril
              ?
              "Почта: shifo.info@bk.ru"
              :
              "Pochta: shifo.info@bk.ru"
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" color="primary">
            {
              isKiril
              ?
              "Реклама бўлими:"
              :
              "Reklama bo‘limi:"
            }
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="subtitle1">
            {
              isKiril
              ?
              "Тел: (71) 209-71-12; (71) 209-49-15 (Қўнғироқлар 09:00 дан 18:00 гача қабул қилинади)"
              :
              "Tel: (71) 209-71-12; (71) 209-49-15 (Qo‘ng‘iroqlar 09:00 dan 18:00 gacha qabul qilinadi)"
            }
          </Typography>
        </Grid>
    </Grid>
  );
}
