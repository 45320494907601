import React, {useState, useEffect, useContext} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import ReactPlayer from 'react-player';
import Box from "@material-ui/core/Box";
import {LanguageContext} from "../components/LanguageProvider";
import app from "../base";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  logo:{
    height:50,
    width:250,
    alignSelf:"center"
  },
  logoContainer:{
    flex:1,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  drawer:{
    width: 250
  },
  subTagFilterText:{
    color:theme.palette.common.white
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  }, 
  buttonText:{
    color: theme.palette.common.white
  }
}));
const db = app.firestore();

export default function InterviewsListPage(props){
  const classes = useStyles()
  const [fetchLoading, setFetchLoading] = useState(true)
  const [interviews, setInterviews] = useState([])
  
  const [lastItem, setLastItem] = useState(null);
  const [fetchingMore, setFetchingMore] = useState(false);
  const limitNumber = 6
  const {isKiril} = React.useContext(LanguageContext)

  const handleMore = () => {
    if(!lastItem){
      return null
    }
    setFetchingMore(true)
    db.collection("interviews").orderBy("date_created", "desc").startAfter(lastItem).limit(limitNumber).get()
    .then(snapshot => {
        if(snapshot.docs.length < limitNumber){
            setLastItem(false)
        } else {
            setLastItem(snapshot.docs[snapshot.docs.length-1])
        }
        let interviews = []
        snapshot.forEach(doc=>{
            interviews.push({...doc.data(), id:doc.id})
        })
        setInterviews(prevState =>([...prevState, ...interviews]))
        setFetchingMore(false)
    })
  }

  useEffect(() => {
    const fetchData = async () => {
        setFetchLoading(true)     
        db.collection("interviews").orderBy("date_created", "desc").limit(limitNumber).get()
        .then(snapshot => {
            if(snapshot.docs.length < limitNumber){
                setLastItem(false)
            } else {
                setLastItem(snapshot.docs[snapshot.docs.length-1])
            }
            let interviews = []
            snapshot.forEach(doc => {
                interviews.push({...doc.data(), id:doc.id})
            })
            setInterviews(interviews)
            setFetchLoading(false)
        })
    }
    fetchData()
  },[])
  
  return (
    <Grid item xs={12} md={8}>
        <Typography variant="h5" gutterBottom>
          {
            isKiril
            ?
            "Видео суҳбатлар"
            :
            "Video suhbatlar"
          }
        </Typography>
        <Divider />
        {
          !fetchLoading 
          ?
          <Grid xs={12} style={{marginTop:2}} item container spacing={2}>
            {
              interviews.map(interview => {
                return (
                    <Grid container xs={12} md={6}>
                        <Paper style={{width: "100%", margin: 10}} elevation={1}>
                            <Grid container item xs={12} justify="space-evenly" style={{height: 280}}>
                                <Grid alignItems="flex-end" container item xs={12}>
                                <Grid container item xs={12} style={{backgroundColor:"#f5f5f5"}}>

                                    <ReactPlayer style={{margin: 10}} height={200} width="100%" url={interview.url} controls={true}/>
                                    <Box>
                                        <Typography color="primary" style={{fontSize:14, color:"#000", fontWeight:"bold", margin: 10}}>
                                            {
                                              interview.kiril.length > 100
                                              ? 
                                              `${isKiril ? interview.kiril.slice(0,100) : interview.latin.slice(0,100)}...`
                                              :
                                              `${isKiril ? `${interview.kiril}`.slice(0,100) : `${interview.latin}`.slice(0,100)}`
                                            }
                                        </Typography>
                                    </Box>
                            
                                </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                )
              })
            }
            <Grid xs={12} item container>
              <Button className = {classes.buttonText} fullWidth color={lastItem ? "primary" : "default"} variant="contained" onClick={handleMore}>
                {lastItem ? isKiril ? "Ко‘проқ суҳбатларни юклаш":"Ko‘proq suhbatlarni yuklash" : "..." }
              </Button>
            </Grid>
          </Grid>
          :
          <Grid item xs={12}>
            <div className={classes.progress}>
              <LinearProgress />
            </div>
          </Grid>
        }
    </Grid>
  );
}
