import React from "react"
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box"
import ReactPlayer from 'react-player'

const useStyles = makeStyles((theme) => ({
    quoteBox: {
      padding: theme.spacing(0.3),
      backgroundColor: theme.palette.grey[200],
    },
    quoteContainer: {
        border:"10 simple black",
        borderLeftWidth:50,
        borderLeftColor:theme.palette.primary.main,
    }
}));

export const HtmlParser = ({html}) => {
    const classes = useStyles()
    const transform = (node, index) => {
        if (node.type==="tag" && node.name === "figure" && node.attribs.class === "image"){
            let imageSource
            let imageCaption
            node.children.forEach(nodeSub => {
                if(nodeSub.type === "tag" && nodeSub.name === "img"){
                    imageSource = nodeSub.attribs.src
                }
                if(nodeSub.type === "tag" && nodeSub.name === "figcaption"){
                    imageCaption = nodeSub.children[0] ? nodeSub.children[0].data : ""
                }
            })
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <img style={{ width:"100%", height:"auto"}} src={imageSource}/>
                    </Grid>
                    <Grid item container justify="center" xs={12}>
                        <Typography align="center" variant="subtitle1" color="textSecondary">{imageCaption}</Typography>
                    </Grid>
                </Grid>
            )
            
        }
        if(node.type==="tag" && node.name === "p"){
            if(node.children){
                let videoSourse
                node.children.forEach(nodeSub => {
                    if(nodeSub.type==="tag" && nodeSub.name==="iframe"){
                        videoSourse = nodeSub.attribs.src
                    }
                })
                return(
                    videoSourse ? 
                    <ReactPlayer width="100%" url={videoSourse} />:
                    convertNodeToElement(node, index, transform)
                )
            } else {
                return(
                    <div>
                        {convertNodeToElement(node, index, transform)}
                    </div>
                )
            }
        }
        if (node.type==="tag" && node.name === "blockquote") {
            const defaultProps = {
                m: 1,
                borderColor: 'primary',
            };
            return(
                <Paper elevation={0} className={ classes.quoteBox }>
                    <Box borderLeft={1} {...defaultProps}>
                        <div style={{fontSize:15}}>
                            {convertNodeToElement(node, index, transform)}
                        </div>
                    </Box>
                </Paper>
            ) ;
        }
    }

    const options = {
        decodeEntities: true,
        transform
    };

    return (
        ReactHtmlParser(html, options)
    )
}