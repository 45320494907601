import React, { useState, useEffect } from "react";
import app from "../base";

const db = app.firestore();

export const TagContext = React.createContext()

export const TagProvider = ({children}) => {
    const [tags, setTags] = useState(null);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const fetchTags = async () => {
            setLoading(true)
            await db.collection("tags").doc("tags")
            .get().then(doc => {
                let dict_tags = {}
                let dict_dis = {}
                doc.data().all_tags_new.forEach(tagData => {
                    dict_tags[tagData.slug] = tagData
                })
                doc.data().dis_new.forEach(disData => {
                    dict_dis[disData.slug] = disData
                })
                setTags({...doc.data(), dict_tags: dict_tags, dict_dis: dict_dis})
                setLoading(false)
            })
        }
        fetchTags()
    },[])

    return(
        <TagContext.Provider value={{
            tags,
            loading
        }}>
            {children}
        </TagContext.Provider>
    )
}
