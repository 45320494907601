import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArticleContent from "./ArticleContent"
import LinearProgress from '@material-ui/core/LinearProgress';
import {LanguageContext} from "../components/LanguageProvider"

import {useLocation, useParams} from "react-router-dom";
import app from "../base";

const storage = app.storage()
const storageRef = storage.ref()
const db = app.firestore()

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


export default function Article() {
  const classes = useStyles();
  const { articleId } = useParams()
  const location = useLocation()
  const articleDataFromState = location.state ? location.state.articleData : null  
  const [articleData, setArticleData] = useState(articleDataFromState)
  const [articleContent, setArticleContent] = useState(null)
  const [loading, setLoading] = useState(true)
  const {isKiril} = React.useContext(LanguageContext)
  
  useEffect(() => {
    window.scrollTo(0, 0)
    const fetchData = async () => {
      setLoading(true)
      let temporary_data = articleData
      if(!articleDataFromState){
        await db.collection("articles").doc(articleId)
        .get()
        .then(doc => {
          // Incrementing the view number:
          fetch(`https://us-central1-shifo-info.cloudfunctions.net/incrementViews?id=${doc.id}`)
          .catch(err=>{
            //pass
          })
          // const invocation_request = new XMLHttpRequest();
          // const url = `https://us-central1-shifo-info.cloudfunctions.net/incrementViews?id=${doc.id}`;
          // invocation_request.open("GET", url);
          // invocation_request.send();
          // //
          setArticleData({
            ...doc.data(),
            id: doc.id
          })
          temporary_data = {...doc.data(), id: doc.id}
        })
        .catch(err => console.log(err))
      } else {
        fetch(`https://us-central1-shifo-info.cloudfunctions.net/incrementViews?id=${articleDataFromState.id}`)
        .then(result => console.log(result))
        .catch(err=>alert(err))
      }
      await storageRef.child(`${articleId}/${ isKiril ? "article_content" : temporary_data.isLatinAvailable ? "article_content_latin":"article_content"}.txt`).getDownloadURL().then(function(url) {
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function(event) {
          let blob = xhr.response;
          let myReader = new FileReader();
          myReader.addEventListener("loadend", function(e){
            setArticleContent(e.srcElement.result)
            setLoading(false)
          })
          myReader.readAsText(blob)
        };
        
        xhr.open('GET', url);
        xhr.send();
      }).catch(function(error) {
        if(error.code === "storage/object-not-found"){
          return
        } else {
          alert(error.code)
        }
      });
    }
    fetchData()
  }, [articleId, isKiril])
  
  return (
    <React.Fragment>
      {
        loading
        ?
        <Grid item xs={12} md={8}>
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        </Grid>
        :
        <React.Fragment>
          <ArticleContent data={articleData} content={articleContent}/>
        </React.Fragment> 
      }
    </React.Fragment>
  );
}
