import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Article from "./Article";
import {Route, Switch, useHistory} from "react-router-dom";
import Aloqa from "./Aloqa";
import Haqida from "./Haqida";
import FilteredPosts from "./FilteredPosts";
import Button from "@material-ui/core/Button";
import Arxiv from "./Arxiv";
import Link from "@material-ui/core/Link";
import TelegramIcon from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Interviews from "./InterviewsListPage";
import { LanguageContext } from "../components/LanguageProvider";
import LinearProgress from '@material-ui/core/LinearProgress';

import app from "../base";
import { Typography } from '@material-ui/core';
const db = app.firestore()

const useStyles = makeStyles((theme) => ({
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  footerContainer: {
    backgroundColor: "#00A654",
    marginTop: 20
  },
  linkClass: {
    fontWeight: "bold",
    color:"#fff",
    marginTop: 10,
    marginLeft: 20
  },
  textClass: {
    color: "#fff",
    margin: 20,
    fontWeight:"bold"
  }
}));

export default function Blog() {
  const history = useHistory()
  const classes = useStyles();
  const {isKiril} = React.useContext(LanguageContext)

  const [mainArticles, setMainArticles] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      db.collection("articles")
      .where("published", "==", true)
      .where("is_main", "==", true)
      .orderBy("date_created","desc")
      .limit(3).get()
      .then(snapshot => {
        let articles_list = []
        snapshot.forEach(article => {
          articles_list.push({
            ...article.data(), 
            id: article.id
          })
        })
        setMainArticles(articles_list)
        setLoading(false)
      }).catch(err=>console.log(err))
    }
    fetchData()
  }, [])

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <Header />
        <main>
          {
            !loading
            ?
            <Route exact path="/">
              <Grid container>
                <MainFeaturedPost post={mainArticles[0]} />
                <MainFeaturedPost post={mainArticles[1]} />
                <MainFeaturedPost post={mainArticles[2]} />
              </Grid>
            </Route>
            :
            <Grid item xs={12}>
              <div className={classes.progress}>
                <LinearProgress />
              </div>
            </Grid>
          }
          <Grid container spacing={1}>
            <Switch>
              <Route exact path="/article/:articleId">
                <Article/>
              </Route>
              <Route exact path="/aloqa">
                <Aloqa/>
              </Route>
              <Route exact path="/haqida">
                <Haqida/>
              </Route>
              <Route exact path="/arxiv">
                <Arxiv/>
              </Route>
              <Route exact path="/interviews">
                <Interviews/>
              </Route>
              <Route path="/:filterTag">
                <FilteredPosts/>
              </Route>
              <Route path="/">
                <Main/>
              </Route>
            </Switch>
            <Sidebar/>
          </Grid>
        </main>
      </Container>

      {/*  -- Footer section -- */}
      <Grid container className={classes.footerContainer}>
        <Grid item container xs={12}>
          <Link onClick={() => {history.push("/aloqa")}} component="button" className={classes.linkClass} >
            <Typography>
              {
                isKiril
                ?
                "Алоқа"
                :
                "Aloqa"
              }
            </Typography>
          </Link>
          <Link onClick={() => {history.push("/arxiv")}} component="button" className={classes.linkClass}>
            <Typography>
              {
                isKiril
                ?
                "Газета архиви"
                :
                "Gazeta arxivi"
              }
            </Typography>
          </Link>
          <Link onClick={() => {history.push("/haqida")}} component="button" className={classes.linkClass}>
            <Typography>
              {
                isKiril
                ?
                "Сайт ҳақида"
                :
                "Sayt haqida"
              }
            </Typography>
          </Link>
        </Grid>
        <Grid item container xs={12} md={8}>
          <Typography className={classes.textClass}>
            {
              isKiril
              ?
              "«SHIFO-INFO» - Ўзбекистондаги энг фаол тиббий нашрлардан бири. Сайт ўз олдига Ўзбекистон ва жаҳондаги энг муҳим касалликлар ва тиббиёт янгиликлари ҳақида тезкор, аниқ ва холис маълумот беришни ҳамда уларга даво усулларини ишончли тарзда етказишни ўз олдига мақсад қилиб қўйган. Сайтдан фойдаланиш мутлақо бепул бўлиб, фақатгина интернет траффик сарфланади. Сайтнинг енгил ва тез ишлайдиган мобил версияси"
              :
              "«SHIFO-INFO» - O‘zbekistondagi eng faol tibbiy nashrlardan biri. Sayt o‘z oldiga O‘zbekiston va jahondagi eng muhim kasalliklar va tibbiyot yangiliklari haqida tezkor, aniq va xolis ma'lumot berishni hamda ularga davo usullarini ishonchli tarzda yetkazishni o‘z oldiga maqsad qilib qo‘ygan. Saytdan foydalanish mutlaqo bepul bo‘lib, faqatgina internet traffik sarflanadi. Saytning yengil va tez ishlaydigan mobil versiyasi"
            }
          </Typography>
          <Typography className={classes.textClass}>
            {
              isKiril
              ?
              "Муаллифлик ҳуқуқи © ШИФО-ИНФО 2020."
              :
              "Mualliflik huquqi © ShIFO-INFO 2020."
            }
          </Typography>
        </Grid>
        <Grid item container xs={12} md={4}>
          <TelegramIcon onClick={()=> window.open("https://t.me/shifoinfo_official", "_blank")} style={{color:"#1A8EC9", fontSize: 60, textAlign:"center", margin:20}}/>
          <FacebookIcon style={{color:"#1977F3", fontSize: 60, textAlign:"center", margin:20}}/>
          <YouTubeIcon style={{color:"#CB2429", fontSize: 60, textAlign:"center", margin:20}}/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
