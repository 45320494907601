import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      dark:"#007a47",
      main:"#00AF66",
      light:"#33bf84"
    },
    secondary: {
      dark:"#1f9f79",
      main:"#2DE4AD",
      light:"#57e9bd"
    },
    mycolor1: "#2DE4AD",
    mycolor2: "#13681B" 
  },
  status: {
    danger: 'orange',
  },
});