import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItemPost from "./ListItemPost";
import WideItem from "./wideItem";
import app from "../base";
import { TagContext } from "../components/TagProvider";
import { AddContext } from "../components/AddProvider";
import LinearProgress from "@material-ui/core/LinearProgress";
import { LanguageContext } from "../components/LanguageProvider";

const db = app.firestore();
const useStyles = makeStyles((theme) => ({
  progress: {
    width: '100%'
  }
}));


export default function Main(props) {
  const classes = useStyles();
  const {tags, loading} = useContext(TagContext)
  const { adds } = useContext(AddContext)
  const [content, setContent] = React.useState({})
  const [fetchLoading, setFetchLoading] = React.useState(true)
  const {isKiril} = React.useContext(LanguageContext)

  React.useEffect(() => {
    const fetchData = async () => {
      setFetchLoading(true)
      if(!loading){
        const promises = []
        tags.main_page_tags.forEach(tag => {
          let promise = db.collection("articles").where("tags", "array-contains", tag)
          .where("published", "==", true)
          .orderBy("date_created", "desc")
          .limit(10).get()
          promises.push(promise)
        })
        Promise.all(promises).then(values => {
          values.forEach((snapshot, index) => {
            let articles = []
            snapshot.forEach(article => {
              articles.push({...article.data(), id: article.id})
            })
            setContent(prevState => {
              return({
                ...prevState,
                [tags.main_page_tags[index]]: articles
              })
            })
          })
          setFetchLoading(false)
        })
      }
    }
    fetchData()
  },[loading])

  return (
    <Grid item xs={12} md={8}>
      {
        !fetchLoading
        ?
        <React.Fragment>
          <Grid key={Object.keys(content)[0]} item xs={12}>
            <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom>
              { isKiril ? tags.dict_tags[Object.keys(content)[0]].kiril : tags.dict_tags[Object.keys(content)[0]].latin}
            </Typography>
            <Grid container item xs={12} spacing={1}>
              {
                content[Object.keys(content)[0]].slice(0,2).map(article => {
                  return(
                    <Grid key={article.id} xs={12} md={6} item>
                      <WideItem post={article}/>
                    </Grid>
                  )
                })
              }
              {
                content[Object.keys(content)[0]].slice(2,).map(article => {
                  return(
                    <Grid key={article.id} xs={6} md={3} item>
                      <ListItemPost post={article}/>
                    </Grid>
                  )
                })
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {
              adds.main_middle
              ?
              <img onClick={()=> window.open(adds.main_middle_link, "_blank")}  src={adds.main_middle} style={{width:"100%"}} alt="reklama" />
              :
              null
            }
          </Grid>
          <Grid container>
            <Grid key={Object.keys(content)[1]} item xs={12} md={6}>
              <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom>
                {isKiril ? tags.dict_tags[Object.keys(content)[1]].kiril : tags.dict_tags[Object.keys(content)[1]].latin}
              </Typography>
              <Grid container item xs={12} spacing={1}>
                {
                  content[Object.keys(content)[1]].map(article => {
                    return(
                      <Grid key={article.id} xs={12} item>
                        <WideItem post={article}/>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
            <Grid key={Object.keys(content)[2]} item xs={12} md={6}>
              <Typography style={{fontWeight:"bold"}} variant="h5" gutterBottom>
                { isKiril ? tags.dict_tags[Object.keys(content)[2]].kiril : tags.dict_tags[Object.keys(content)[2]].latin}
              </Typography>
              <Grid container item xs={12} spacing={1}>
                {
                  content[Object.keys(content)[2]].map(article => {
                    return(
                      <Grid key={article.id} xs={12} item>
                        <WideItem post={article}/>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
        :
        <Grid item xs={12}>
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        </Grid>
      }
    </Grid>
  );
}

Main.propTypes = {
  posts: PropTypes.array,
  title: PropTypes.string,
};
