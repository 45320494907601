const monthConverter = {
    "01": "Январь",
    "02": "Февраль",
    "03": "Март",
    "04": "Апрель",
    "05": "Май",
    "06": "Июнь",
    "07": "Июль",
    "08": "Август",
    "09": "Сентябрь",
    "10": "Октябрь",
    "11": "Ноябрь",
    "12": "Декабрь"
}

export function dateConverter(date){
    let year = date.toISOString().split("T")[0].split("-")[0]
    let month = date.toISOString().split("T")[0].split("-")[1]
    let day = date.toISOString().split("T")[0].split("-")[2]
    return `${day} - ${monthConverter[month]} | ${year}`
}