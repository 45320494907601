import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { HtmlParser } from "../components/HtmlParser";
import { TagContext } from "../components/TagProvider";
import { AddContext } from "../components/AddProvider";
import { dateConverter } from "../components/dateConverter";
import { dateConverterLatin } from "../components/dateConverterLatin"
import { useHistory } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { LanguageContext } from "../components/LanguageProvider"

export default function Main(props) {
  const { tags, loading } = useContext(TagContext)
  const { adds } = useContext(AddContext)
  const { content, data } = props;
  const history = useHistory();
  const {isKiril} = React.useContext(LanguageContext)
  
  const handleClick = (tag) => {
    history.push(`/${tag}`);
  }

  return (
    <Grid item xs={12} md={8}>
      <Breadcrumbs aria-label="breadcrumb">
        {
          !loading
          ?
          data.tags.map((tag, index) => {
            return (
              <Link key={tag} color="inherit" onClick={()=>handleClick(tag)}>
                {isKiril ? tags.dict_tags[tag].kiril : tags.dict_tags[tag].latin}
              </Link>
            ) 
          })
          :
          null
        }
      </Breadcrumbs>
      <Divider />
      <div style={{height:5}}/>
      <div>
        <Grid item xs={12}>
          <Typography variant="h4">{isKiril ? data.title : data.isLatinAvailable ? data.title_latin : data.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary" style={{display: 'inline-block'}}>
            {isKiril ? dateConverter(new Date(data.date_created.seconds*1000)) : dateConverterLatin(new Date(data.date_created.seconds*1000))}
          </Typography>
          <Typography color="primary" style={{display: 'inline-block', marginLeft:20}}>
            <VisibilityIcon style={{marginRight:8, verticalAlign: 'middle', display: 'inline-flex'}} color="primary"/>
            {typeof(data.views)=="number" ? data.views : 1 }
          </Typography>
        </Grid>
        <Grid item xs={12} style={{fontSize:20}}>
          <HtmlParser html={content}/>
        </Grid>
      </div>
      <Grid item xs={12}>
        {
          adds.main_bottom
          ?
          <img onClick={()=> window.open(adds.main_bottom, "_blank")} alt="reklama" src={adds.main_bottom} style={{width: "100%"}} />
          :
          null
        }
      </Grid>
    </Grid>
  );
}

Main.propTypes = {
  posts: PropTypes.array,
  title: PropTypes.string,
};
