import React, { useState, useEffect } from "react";
import app from "../base";

const db = app.firestore();

export const AddContext = React.createContext()

export const AddProvider = ({children}) => {
    const [adds, setAdds] = useState({
        header: null,
        main_bottom: null,
        main_middle: null,
        side_one: null,
        side_two: null,
        side_three: null
    });
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const fetchAdds = async () => {
            setLoading(true)
            await db.collection("tags").doc("ad")
            .get().then(doc => {
                setAdds({...doc.data()})
                setLoading(false)
            })
        }
        fetchAdds()
    },[])

    return(
        <AddContext.Provider value={{
            adds,
            loading
        }}>
            {children}
        </AddContext.Provider>
    )
}
