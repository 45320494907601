const monthConverter = {
    "01": "Yanvar",
    "02": "Fevral",
    "03": "Mart",
    "04": "Aprel",
    "05": "May",
    "06": "Iyun",
    "07": "Iyul",
    "08": "Avgust",
    "09": "Sentyabr",
    "10": "Oktyabr",
    "11": "Noyabr",
    "12": "Dekabr"
}

export function dateConverterLatin(date){
    let year = date.toISOString().split("T")[0].split("-")[0]
    let month = date.toISOString().split("T")[0].split("-")[1]
    let day = date.toISOString().split("T")[0].split("-")[2]
    return `${day} - ${monthConverter[month]} | ${year}`
}